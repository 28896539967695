import {
  applyFont,
  copernicus,
  copernicusBold,
  notoSans,
  notoSansBold,
  polaris,
  polarisBold,
} from '@chat-dit/ui-util-fonts';
import { Typography, TypographyProps } from '@mui/material';

const fontMap = {
  copernicus,
  copernicusBold,
  notoSans,
  notoSansBold,
  polaris,
  polarisBold,
};

type TextProps = TypographyProps & {
  font?:
    | 'copernicus'
    | 'copernicusBold'
    | 'notoSans'
    | 'notoSansBold'
    | 'polaris'
    | 'polarisBold';
};

function Text(props: TextProps) {
  const withProvidedSx: TextProps['sx'] = props.sx ?? {};
  const withSx: TextProps['sx'] = {
    ...withProvidedSx,
    ...(props.font ? applyFont(fontMap[props.font]) : {}),
    ...(props.fontWeight ? { fontWeight: props.fontWeight } : {}),
  };
  return (
    <Typography {...props} sx={withSx}>
      {props.children}
    </Typography>
  );
}

export default Text;
