import { Theme, useTheme } from '@mui/material';

function ImageAttachmentIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1230_13381"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_1230_13381)">
        <path
          d="M4.66675 21C4.21675 21 3.82642 20.8348 3.49575 20.5043C3.16525 20.1736 3 19.7833 3 19.3333V4.66675C3 4.21675 3.16525 3.82642 3.49575 3.49575C3.82642 3.16525 4.21675 3 4.66675 3H19.3333C19.7833 3 20.1736 3.16525 20.5043 3.49575C20.8348 3.82642 21 4.21675 21 4.66675V19.3333C21 19.7833 20.8348 20.1736 20.5043 20.5043C20.1736 20.8348 19.7833 21 19.3333 21H4.66675ZM4.66675 19.3333H19.3333V4.66675H4.66675V19.3333ZM5.93325 17.05H18.0833L14.3833 12.1168L11.1833 16.3L8.88325 13.1833L5.93325 17.05Z"
          fill="#4D8EFF"
        />
      </g>
    </svg>
  );
}

export default ImageAttachmentIcon;
