import { Theme, useTheme } from '@mui/material';

function DocumentAttachmentIcon({
  color,
}: {
  color: string | ((theme: Theme) => string);
}) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1230_13368"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_1230_13368)">
        <path
          d="M7.98325 17.8333H16.0167V16.1668H7.98325V17.8333ZM7.98325 13.6668H16.0167V12H7.98325V13.6668ZM5.66675 22C5.21675 22 4.82642 21.8348 4.49575 21.5043C4.16525 21.1736 4 20.7833 4 20.3333V3.66675C4 3.21675 4.16525 2.82642 4.49575 2.49575C4.82642 2.16525 5.21675 2 5.66675 2H14.35L20 7.65V20.3333C20 20.7833 19.8347 21.1736 19.5042 21.5043C19.1736 21.8348 18.7832 22 18.3333 22H5.66675ZM13.5167 8.43325V3.66675H5.66675V20.3333H18.3333V8.43325H13.5167Z"
          fill="#4D8EFF"
        />
      </g>
    </svg>
  );
}

export default DocumentAttachmentIcon;
