import { Theme, useTheme } from '@mui/material';

function AgentIcon({ color }: { color: string | ((theme: Theme) => string) }) {
  const theme = useTheme();
  const fillColor = typeof color === 'function' ? color(theme) : color;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1759_28039"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1759_28039)">
        <path
          fill={fillColor}
          d="M4.5 15C3.66667 15 2.95833 14.7083 2.375 14.125C1.79167 13.5417 1.5 12.8333 1.5 12C1.5 11.1667 1.79167 10.4583 2.375 9.875C2.95833 9.29167 3.66667 9 4.5 9V7C4.5 6.45 4.69583 5.97917 5.0875 5.5875C5.47917 5.19583 5.95 5 6.5 5H9.5C9.5 4.16667 9.79167 3.45833 10.375 2.875C10.9583 2.29167 11.6667 2 12.5 2C13.3333 2 14.0417 2.29167 14.625 2.875C15.2083 3.45833 15.5 4.16667 15.5 5H18.5C19.05 5 19.5208 5.19583 19.9125 5.5875C20.3042 5.97917 20.5 6.45 20.5 7V9C21.3333 9 22.0417 9.29167 22.625 9.875C23.2083 10.4583 23.5 11.1667 23.5 12C23.5 12.8333 23.2083 13.5417 22.625 14.125C22.0417 14.7083 21.3333 15 20.5 15V19C20.5 19.55 20.3042 20.0208 19.9125 20.4125C19.5208 20.8042 19.05 21 18.5 21H6.5C5.95 21 5.47917 20.8042 5.0875 20.4125C4.69583 20.0208 4.5 19.55 4.5 19V15ZM9.5 13C9.91667 13 10.2708 12.8542 10.5625 12.5625C10.8542 12.2708 11 11.9167 11 11.5C11 11.0833 10.8542 10.7292 10.5625 10.4375C10.2708 10.1458 9.91667 10 9.5 10C9.08333 10 8.72917 10.1458 8.4375 10.4375C8.14583 10.7292 8 11.0833 8 11.5C8 11.9167 8.14583 12.2708 8.4375 12.5625C8.72917 12.8542 9.08333 13 9.5 13ZM15.5 13C15.9167 13 16.2708 12.8542 16.5625 12.5625C16.8542 12.2708 17 11.9167 17 11.5C17 11.0833 16.8542 10.7292 16.5625 10.4375C16.2708 10.1458 15.9167 10 15.5 10C15.0833 10 14.7292 10.1458 14.4375 10.4375C14.1458 10.7292 14 11.0833 14 11.5C14 11.9167 14.1458 12.2708 14.4375 12.5625C14.7292 12.8542 15.0833 13 15.5 13ZM8.5 17H16.5V15H8.5V17ZM6.5 19H18.5V7H6.5V19Z"
        />
      </g>
    </svg>
  );
}

export default AgentIcon;
